<template>
    <div class="ueber-mich">
        <h1>Über mich</h1>
        <div class="block block--tablet-rev-col">
            <div>
                <i>
                    "Ich bin ein interessierter, offener und direkter Mensch, der es mag, den Dingen auf den Grund zu gehen.“
                </i>
                <p>
                    Fasziniert bin ich von der Vielfältigkeit des menschlichen Seins. Menschen in unterschiedlichen Lebenslagen und -fragen sind bei mir willkommen. Ich biete den Menschen Raum und Platz für Fragen, Anliegen für ihre Probleme, Bedürfnisse und Wünsche. Neugierig und offen zuhören, um gemeinsam eine Strategie und Möglichkeit der Veränderung zu entwickeln, stehen dabei im Mittelpunkt. 
                </p>
                
                <h2>Meine Arbeitsweise auf den Punkt gebracht:</h2>
                <ul>
                    <li>Ein achtungsvoller Umgang mit persönlichen und intimen Themen ist mir ein wichtiges Anliegen.</li>
                    <li>Ausdauer zeige ich bei der Definition und Aktivierung Ihrer eigenen Ressourcen.</li>
                    <li>Mein Lebensweg ist geprägt durch unterschiedliche Berufserfahrungen, die es mir ermöglichen, in der Therapie unterschiedliche Sichtweisen zu beleuchten.</li>
                </ul>
    
                <h2>Derzeitige Projekte außerhalb meiner Tätigkeit in der Praxis:</h2>
                <ul>
                    <li>Supervision an der Fachhochschule OST - SG als Supervisorin.</li>
                    <li>Supervision in Institutionen im Kinder- und Jugendbereich.</li>
                    <li>Supervision in Institutionen der Sozialarbeit mit Erwachsenen.</li>
                    <li>Fort- und Weiterbildungen im Bereich der Sozialpädagogik.</li>
                </ul>
    
                <h2>Berufserfahrung:</h2>
                <ul>
                    <li>Sozialpädagogische Jugendwohngruppe</li>
                    <li>Nachgehende-Sozialpsychiatrische-Sozialarbeit.</li>
                    <li>Koordination der Kinderbetreuungseinrichtungen der Stadt Feldkirch.</li>         
                    <li>Betreuung der Frauennotwohnung in Dornbirn.</li> 
                    <li>Aufbau und Leitung der Beratungsstelle für Familienplanung, Schwangerschaft und Sexualität in Sargans.</li>
                    <li>Arbeit in freier Praxis in der Praxisgemeinschaft in Buchs.</li>
                </ul>
    
                <h2>Ausbildung:</h2>
                <ul>
                    <li>Ausbildung zur Sozialpädagogin an der Höheren Fachschule für Sozialpädagogik in Rorschach</li>
                    <li>Sozialarbeiterin in Bregenz</li>
                    <li>Ausbildung an der Wiener Akademie für Ganzheitsmedizin zur Sexualberaterin und Sexualpädagogin</li>
                    <li>Ausbildung zur Sexualtherapeutin bei der Deutschen Gesellschaft für Sexualforschung Uni Jena/Uni Innsbruck</li> 
                    <li>Ausbildung zur systemischen Supervisorin und Coach am Institut für Systemische Impulse Zürich - BSO-Abschluss</li>  
                </ul>
    
                <h2>Zusatzweiterbildung:</h2>
                <ul>
                    <li>IMAGO - Beziehungstherapie nach Dr. Harville Hendrix</li>
                    <li>div. hypnosystemische Fort- und Weiterbildung - Gunther Schmidt</li>
                    <li>Fort- und Weiterbildung in der systemischen Paar und Sexualtherapie - Ulrich Clement</li> 
                </ul>
                
                <h2>Persönlich</h2>
                <ul>
                    <li>Verheiratet, eine erwachsene Tochter</li>
                </ul>
            </div>
            <img src="@/assets/images/banner-about-me.jpg" class="img ueber-mich__img" alt="Bettina Thaler hält ein Buch in ihren Händen." />
        </div>
        <scroll-top />
    </div>
</template>
<script>
import ScrollTop from '../components/ScrollTop.vue';
export default {
    name: 'UeberMich',
    components: {
        ScrollTop
    }
}
</script>
<style lang="css" scoped>
    h2 {
        font-family: DMSansBold, sans-serif;
        font-size: var(--font-size-xl);
    }
    .ueber-mich__img {
        width: 45%;
    }
    @media (max-width: 750px) {
        .ueber-mich__img {
            align-self: center;
        }
    }
    @media (max-width: 500px) {
        .ueber-mich__img {
            width: 126%;
            margin-left: -13%;
            border-radius: 0;
            height: 400px;
        }
    }
</style>