<template>
    <div class="footer">
        <div class="block block--mobile-col main__inner block--footer block--no-margin-bottom">
            <div>
                <p class="footer__links">
                    <router-link to="/impressum">Impressum</router-link> 
                    <br />
                    <button @click="showCookieBanner" type="button">Cookies setzen</button>
                </p>
                <div class="footer__medias">
                    <a href="mailto:info@praxis-bettinathaler.ch" aria-label="Schreiben Sie Bettina Thaler eine E-Mail.">
                        <div>
                            <ios-mail-icon w="30" h="30" class="footer__icon" />
                        </div>
                    </a>
                    <a href="tel:0041796455850">
                        <div>
                            <ios-call-icon w="30" h="30" class="footer__icon" aria-label="Rufen Sie Bettina Thaler an." />
                        </div>
                    </a>
                    <a href="https://www.linkedin.com/in/bettina-thaler-3a85961b7/" target="_blank" aria-label="Besuchen Sie das LinkedIn Profil von Bettina Thaler.">
                        <div>
                            <logo-linkedin-icon w="30" h="30" class="footer__icon" />
                        </div>
                    </a>
                </div>
            </div>
            <div class="footer__impressum">
                <span>Bettina Thaler</span><br />
                <span>Bahnhofstrasse 4</span><br />
                <span>9470 Buchs SG</span><br />
                <span>T 0041(79) 6455850</span><br />
                <a href="mailto:info@praxis-bettinathaler.ch">info@praxis-bettinathaler.ch</a>
            </div>
        </div>
        <p class="footer__subline">
            <br />
            &copy; {{ new Date().getFullYear() }} Bettina Thaler <br />
            Designed and programmed by <a href="https://arminnovacek.com" target="_blank" aria-label="Besuchen Sie die Website von Armin Novacek, dem Entwickler dieser Homepage.">Armin Novacek</a>
        </p>
    </div>
</template>
<script>
import LogoLinkedinIcon from 'vue-ionicons/dist/logo-linkedin.vue'

export default {
    name: 'Footer',
    components: {
        LogoLinkedinIcon
    },
    methods: {
        showCookieBanner () {
            this.$store.commit('setShowCookieBanner', true);
        }
    }
}
</script>
<style lang="css" scoped>
    .footer {
        height: 320px;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 0;
        background-color: var(--background-accent);
    }
    .footer__medias {
        display: flex;
        width: 200px;
        justify-content: space-between;
    }
    .footer__medias a div {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--action-primary);
    }
    .footer__medias a {
        color: #FFF;
    }
    .footer__impressum {
        margin-top: 50px;
        text-align: right;
    }
    .footer__links a {
        color: var(--text-primary);
        text-decoration: none;
        cursor: pointer;
    }
    .footer__links button {
        all: unset;
        cursor: pointer;
        margin-top: 8px;
    }
    .footer__links {
        margin-top: 50px;
        margin-bottom: 20px;
    }
    @media (max-width: 500px) {
        .footer {
            text-align: center;
            height: 450px;
        }
        .footer__impressum {
            text-align: center;
        }
        .footer__medias {
            margin: auto;
        }
        .block--footer {
            gap: 0;
        }
    }
    .footer__subline {
        color: var(--disabled);
        font-size: var(--font-size-small);
        text-align: center;
        padding: 0 5%;
    }
    .footer__subline a {
        color: var(--disabled);
        text-decoration: none;
        font-weight: normal;
    }
</style>