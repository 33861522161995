<template>
    <div v-if="showFlyout">
        <div @click="closeFlyout" class="flyout__body-overlay" />
        <div class="flyout">
            <button @click="closeFlyout" class="btn btn--naked flyout__close-btn" type="button">
                <ios-close-icon w="42" h="42" />
            </button>
            <nav class="flyout__nav">
                <router-link to="/#home" @click="navigateToId('.main')">Home</router-link>
                <router-link to="/#fachgebiete" @click="navigateToId('#fachgebiete')">Fachgebiete</router-link>
                <router-link to="/#ueber-mich" @click="navigateToId('#ueber-mich')">Über mich</router-link>
                <router-link to="/#praxis" @click="navigateToId('#praxis')">Praxis</router-link>
                <router-link to="/#terminanfrage" @click="navigateToId('#terminanfrage')">
                    <button class="btn btn--primary" type="button">Terminanfrage</button>
                </router-link>
            </nav>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Flyout',
    computed: {
        showFlyout () {
            return this.$store.state.showFlyout;
        }
    },
    methods: {
        closeFlyout () {
            this.$store.commit('setShowFlyout', false);
        },
        navigateToId (id) {
            // wait for navigation - with setTimeout function in is queue
            setTimeout(() => {
                document.querySelector(id).scrollIntoView({ behavior: 'smooth'  });
                this.closeFlyout();
            }, 0);
        }
    }
}
</script>
<style lang="css" scoped>
    .flyout {
        width: 50%;
        max-width: 200px;
        height: 100%;
        z-index: 3;
        right: 0;
        top: 0;
        position: fixed;
        background-color: var(--background-card);
        border-left: 1px solid var(--border-card);
        padding: 0 5%;
    }
    .flyout__nav {
        display: flex;
        flex-direction: column;
        font-size: var(--font-size-small);
    }
    .flyout__nav a {
        cursor: pointer;
        color: var(--text-primary);
        text-decoration: none;
        padding: 20px 0;
    }
    .flyout__nav button {
        font-size: var(--font-size-small);
    }
    .flyout__close-btn {
        color: var(--text-color);
        margin: 8px -5px 8px auto;
        padding: 0;
        display: block;
    }
    .flyout__body-overlay {
        background-color: #FFF;
        opacity: 0.8;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
    }
    @media (min-width: 500px) {
        .flyout {
            padding: 0 10%;
        }
    }
    @media (min-width: 750px) {
        .flyout, .flyout__body-overlay {
            display: none;
        }
    }
</style>