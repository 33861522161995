<template>
    <div class="supervision subpage">
        <h1>Supervision</h1>
        <p class="subpage__introduction">
            Supervision ist eine Form der Beratung für Mitarbeiter/-innen. Ob Konflikte im Team, Mobbing oder Stress bei der Arbeit - Supervision soll dabei helfen, die eigenen Ressourcen besser zu nutzen und Lösungen für Probleme und Herausforderungen zu finden. Supervision kann in verschiedenen Berufsfeldern eingesetzt werden und schafft Möglichkeiten, neue Perspektiven und Lösungen zu entwickeln.
        </p>
        <div class="block block--tablet-col">
            <card 
                title="Ablauf der Supervision"
                content="Damit eine Supervision erfolgreich funktioniert, werden Ziele klar definiert. Diese werden an die Person, das Team oder das Unternehmen individuell angepasst."
                class="card--unset-fixed-width"
            />
            <card
                title="Ziele einer Supervision"
                content="<ul>
                    <li>Reflexion des Arbeitsalltages</li>
                    <li>Erweiterung der beruflichen Kompetenzen</li>
                    <li>Verbessertes Arbeitsklima</li>
                    <li>Förderung der Teamentwicklung</li>
                    <li>Konfliktlösungen</li>
                    <li>Steigerung der Zufriedenheit am Arbeitsplatz</li>
                    <li>Optimierung der Arbeitsergebnisse</li>
                    <li>Anregungen für neue Lernprozesse</li>
                    <li>Qualitätssicherung</li>
                    <li>Konzepterarbeitung -Ergänzung - Vertiefung</li>
                    </ul>"
                class="card--unset-fixed-width"
            />
        </div>
        <h3>Mein Angebot</h3>
        <p>
            Als Supervisorin begleite und unterstütze ich Sie dabei - als Einzelperson, als Gruppe, als Team - neue Dimensionen und Möglichkeiten zu entdecken. Durch gezieltes Querdenken und oft überraschede Fragen initiiere ich als Supervisorin das Finden neuer Sichtweisen, Antworten und Lösungen.
        </p>
        <scroll-top />
    </div>
</template>

<script>
import Card from '../components/Card.vue';
import ScrollTop from '../components/ScrollTop.vue';
export default {
    name: 'Supervision',
    components: { 
        Card,
        ScrollTop
    },
}
</script>