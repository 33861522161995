<template>
    <header class="header">
        <div class="main__inner">
            <router-link to="/" class="header__logo">
                <img src="@/assets/images/logo-praxis-b-thaler.png" alt="Praxis Bettina Thaler Logo" />
            </router-link>
            <nav class="header__nav">
                <router-link to="/#home" @click="navigateToId('.main')">Home</router-link>
                <router-link to="/#fachgebiete" @click="navigateToId('#fachgebiete')">Fachgebiete</router-link>
                <router-link to="/#ueber-mich" @click="navigateToId('#ueber-mich')">Über mich</router-link>
                <router-link to="/#praxis" @click="navigateToId('#praxis')">Praxis</router-link>
                <router-link to="/#terminanfrage" @click="navigateToId('#terminanfrage')">
                    <button class="btn btn--primary" type="button">Terminanfrage</button>
                </router-link>
            </nav>
            <button @click="showFlyout" class="btn btn--naked header__hamburger" type="button" value="Menü">
                <ios-menu-icon w="35" h="35" />
            </button>
        </div>
    </header>
</template>

<script>
export default {
    name: 'Header',
    methods: {
        showFlyout () {
            this.$store.commit('setShowFlyout', true);
        },
        navigateToId (id) {
            // wait for navigation - with setTimeout function in is queue
            setTimeout(() => {
                document.querySelector(id).scrollIntoView({ behavior: 'smooth'  })
            }, 0);
        }
    }
}
</script>

<style lang="css" scoped>
    .header {
        z-index: 2;
        width: 100%;
        height: 60px;
        position: fixed;
        top: 0;
        left: 0;
        background-color: var(--background-header);
        font-size: var(--font-size-small);
    }
    .header .main__inner {
        display: flex;
        justify-content: space-between;
        height: 60px;
        gap: 40px;
    }
    .header__logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .header__logo img {
        width: 160px;
    }
    .header__nav {
        display: flex;
        justify-content: space-between;
        width: 70%;
        letter-spacing: 0.5px;
        max-width: 700px;
        align-items: center;
    }
    .header__nav a {
        cursor: pointer;
        color: var(--text-primary);
        text-decoration: none;
    }
    .btn {
        font-size: var(--font-size-small);
    }
    .header__hamburger {
        margin: auto 0;
        color: var(--text-primary);
        padding: 0;
        display: none;
    }

    @media (max-width: 750px) {
        .header__hamburger {
            display: block;
        }
        .header__nav {
            display: none;
        }
    }

</style>