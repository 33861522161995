<template>
    <div class="impressum">
        <h1>Impressum und Datenschutz</h1>
        <h2>Impressum</h2>
        <h3>Verantwortlich für den Inhalt der Seiten:</h3>
        <p>
            Bettina Thaler<br />
            Bahnhofstrasse 4<br />
            9470 Buchs SG
        </p>
        <p>
            info@praxis-bettinathaler.ch<br />
            Telefon: 0041(79) 6455850
        </p>

        <p>Handelsreg. Nr. CHE-310.138.311</p>

        <h3>Copyright</h3>
        <p>Das Copyright für sämtliche Inhalte dieser Website liegt bei Bettina Thaler.</p>

        <h3>Disclaimer</h3>
        <p>Alle Texte und Links wurden sorgfältig geprüft und werden laufend aktualisiert. Wir sind bemüht, richtige und vollständige Informationen auf dieser Website bereitzustellen, übernehmen aber keinerlei Verantwortung, Garantien oder Haftung dafür, dass die durch diese Website bereitgestellten Informationen, richtig, vollständig oder aktuell sind. Wir behalten uns das Recht vor, jederzeit und ohne Vorankündigung die Informationen auf dieser Website zu ändern und verpflichten uns auch nicht, die enthaltenen Informationen zu aktualisieren. Alle Links zu externen Anbietern wurden zum Zeitpunkt ihrer Aufnahme auf ihre Richtigkeit überprüft, dennoch haften wir nicht für Inhalte und Verfügbarkeit von Websites, die mittels Hyperlinks zu erreichen sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die durch Inhalte verknüpfter Seiten entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde. Dabei ist es gleichgültig, ob der Schaden direkter, indirekter oder finanzieller Natur ist oder ein sonstiger Schaden vorliegt, der sich aus Datenverlust, Nutzungsausfall oder anderen Gründen aller Art ergeben könnte.</p>

        <h2>Datenschutz</h2>
        <p>Für die Sicherheit der Datenübertragung im Internet können wir keine Gewähr übernehmen, insbesondere besteht bei der Übertragung von Daten per E-Mail die Gefahr des Zugriffs durch Dritte.</p>
        <p>Einer Nutzung der im Impressum veröffentlichten Kontaktdaten durch Dritte zu Werbezwecken wird hiermit ausdrücklich widersprochen. Der Betreiber behält sich für den Fall unverlangt zugesandter Werbe- oder Informationsmaterialien ausdrücklich rechtliche Schritte vor.</p>
        <p>Sollten einzelne Regelungen oder Formulierungen dieses Haftungsausschlusses unwirksam sein oder werden, bleiben die übrigen Regelungen in ihrem Inhalt und ihrer Gültigkeit hiervon unberührt.</p>

        <h3>Bilder- und Textnachweis</h3>
        <p>
            Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.<br />
            Alle Texte sind urheberrechtlich geschützt.
        </p>

        <p>
            Die Bilder- und Textrechte liegen bei der folgenden Person:<br />
            Bettina Thaler
        </p>

        
        <h3>Google Web Fonts</h3>
        <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von Google findet dabei nicht statt.</p>
        
        <h3>Google Maps</h3>
        <p>Diese Website verwendet die Google Maps API, um geographische Informationen visuell darzustellen. Die Einbindung von Google Maps erfolgt erst nach Zustimmung der Nutzung von Cookies. Durch die Zustimmung der Cookies erklären Sie sich mit der Erfassung, Bearbeitung sowie der Nutzung der automatisiert erhobenen Daten durch Google Maps und der zugehörigen Dienste einverstanden. Ausführliche Details finden Sie auf der Website von Google.</p>
        <p>Hinweis: Die Nutzung von Google Maps und der damit verbundenen Dienste setzt eine Internetverbindung sowie eventuell zusätzliche Nutzerkonten voraus. Es können zusätzliche Kosten anfallen. Die Nutzung von Google Maps erfolgt auf eigene Gefahr. Die Betreiberin dieser Website übernimmt keine Verantwortung für mögliche Schäden, die aus der Nutzung von Google Maps entstehen.</p>

        <scroll-top />
    </div>
</template>
<script>
import ScrollTop from '../components/ScrollTop.vue';
export default {
    name: 'Impressum',
    components: {
        ScrollTop
    }
}
</script>
<style lang="css">
    
</style>